
.footer {
  background: var(--main-color-2);
  padding-left: 2vh;
  padding-right: 2vh;
  padding-top: 1vh;
  padding-bottom: 1vh;
  top: 0;
  left: auto;
  right: 0;
  position: sticky;
  height: calc(40px + 1.4vh);
  width: 100%;
}

.navfooter {
  width: 100%;
  height: 100%;
  font-size: calc(12px + 0.1vh + 0.1vw);
  font-weight: 500;
  font-family: 'Rethink Sans', sans-serif;
}

.nav-links a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  color: var(--main-color-5);
  padding-left: 1vh;
  padding-right: 1vh;
  padding-top: 0.4vh;
  padding-bottom: 0.4vh;
  text-wrap: nowrap;
}

.nav-links {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.ig-icon {
  height: 100%;
}

.ig-img {
  height: 100%;
  width: 100%;
}

/* weird issue with iphone, icon goes bigger, so limit max size */
@media (max-width: 1024px) {
  .ig-img {
    max-width: 40px;
  }
}

.tempo-logo-footer {
  height: 100%;
}

.footer-text {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  font-family: 'Rethink Sans', sans-serif;
  color: var(--main-color-5);
  height: 100%;
}