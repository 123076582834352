.map-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('../assets/map.svg');
  color: var(--main-color-5);
}

.map-link {
  text-decoration: none;
  color: inherit;
}

.map-logo {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
}

.map-address {
  font-size: 23px;
  font-weight: 500;
  width: 50vw;
}
