.joinnow {
    background-color: #efefef;
    font-family: var(--font-family);
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    padding-bottom: 6vh;
    padding-top: 6vh;
    padding-left: 31px;
}
.joinnow h2 {
  font-family: var(--font-family);
  text-align: left;
  font-size: 40px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--main-color-2);
}
.joinnow .join-container {
  margin-top: 32px;
  padding-right: 44px;
  color: var(--main-color-2);
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
}
.joinnow .join-container .join-button {
  margin-top: 42px;
  display: inline-block;
  height: 100%;
  padding: 14px 25px;
  background-color: var(--main-color-2);
  color: white;
  text-decoration: none;
  border: none;
  cursor: pointer;
  font-family: var(--font-family);
  font-size: 21px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  font-weight: 600;

  border-radius: 8px;
}
.joinnow .join-container .join-button:hover {
  background-color: #333;
}

.confirmation-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.confirmation-text {
  font-size: 2.8vh;
  font-family: var(--font-family);
  padding: 5vh;
  padding-bottom: 2vh;
  text-align: center;
}


.follow-us {
    font-size: 2.2vh;
    font-family: var(--font-family);
    text-align: center;
    padding-left: 5vh;
    padding-right: 5vh;
    padding-bottom: 3vh;
  }

  .logos-container {
      display: flex;
      flex-direction: row;
      height: 100%;
      width: 100%;
      align-items: center;
      justify-content: center;
  }

.follow-us-icon {
  height: 100%;
  width: 100%;
  max-width: 34px;
  max-height: 34px;
  padding-bottom: 2vh;
  height: 100%;
  width: 100%;
}

.follow-us-icon img {
  height: 100%;
  width: 100%;
}

.follow-us-icon svg {
  fill: #333;
  stroke: #333;
  width: 34px;
  height: 34px;
}

.follow-us-icon svg path {
  fill: #333;
  stroke: #333;
}

@media (min-width: 800px) {
  .joinnow {
    padding: 80px;
  }

  .joinnow h2 {
    word-wrap: nowrap;
  }

  .join-container p {
    max-width: 400px;
  }
}
