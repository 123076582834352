.navigation-menu {
  display: flex;
  flex-direction: column;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: var(--main-color-1);
  z-index: 1000;
  padding: 20px;
}

.navigation-menu-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 29px;
  width: 100%;
}

.navigation-menu-spacer {
  min-height: 36px;
  min-width: 36px;
  margin-left: 16px;
}

.navigation-menu-logo {
  flex-shrink: 0;
  height: 38px;
}

.navigation-menu-logo path {
  fill: white;
}

.close-button {
  flex-shrink: 0;
  min-height: 36px;
  min-width: 36px;
  margin-right: 16px; /* keep same as padding-left on spacer to center logo */
}

.close-button path {
  fill: var(--main-color-5);
}

.navigation-menu a {
  text-decoration: none;
}

.navigation-menu-items {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 52px;
}

.navigation-menu-item {
  font-family: var(--font-family);
  font-size: 26px;
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.navigation-menu-item:hover {
  color: #aaa;
}

.sign-in-button {
  border-radius: 8px;
  border: solid 1px #fff;
  padding: 14px 40px 14px 40px;
  text-align: center;
  width: fit-content;
  text-wrap: nowrap;
  margin-top: 20px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.sign-in-button:hover {
  background-color: #fff;
  color: #000;
}

.footer-content {
  margin-top: 65px;
  display: flex;
  flex-direction: column;
  font-family: var(--font-family);
  color: var(--main-color-5);
  text-align: center;
  font-size: 14px;
  margin-bottom: 20px;
  font-size: 18px;
  color: #fff;
  gap: 65px;
}

.footer-content-bold {
  font-weight: 800;
}

.instagram-icon {
  width: 30px;
  height: 30px;
}

@media (min-width: 800px) {
  .close-button {
    display: none;
  }
}

/* Responsive adjustments for different screen sizes */
@media (max-width: 768px) {
  .navigation-menu {
    width: 100%;
  }
}
