.contact-form-container {
  --form-input-height: 22px;
  --form-font-size: 22px;
}

.contact-form-container h2 {
  font-family: var(--font-family);
  font-size: 48px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 39px;
  padding-bottom: 22px;
  border-bottom: 2px solid var(--main-color-1);
}

.contact-form-container p {
  text-align: left;
  font-size: 1rem;
  margin-bottom: 2em;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  font-family: var(--font-family);
  color: var(--main-color-1);
}

.contact-form-container form {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.contact-form-container input {
  align-items: center;
  text-align: left;
  text-justify: center;
  height: var(--form-input-height);
  font-size: var(--form-font-size);
  border: none;
  border-radius: 0;
  margin-left: 5px;
  font-family: var(--font-family);
  color: var(--main-color-1);
}

.contact-form-container ::placeholder {
  font-size: var(--form-font-size);
  font-family: var(--font-family);
  color: var(--main-color-1);
}

.input-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:flex-start;
  border-bottom: 2px solid var(--main-color-1);
  padding-bottom: 10px;
  margin-bottom: 38px;
}

.textbox-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  border-bottom: 2px solid var(--main-color-1);
  margin-bottom: 60px;
}

.contact-form-container textarea {
  min-height: 50px;
  height: var(--form-input-height);
  font-size: var(--form-font-size);
  width: 100%;
  align-items: center;
  text-align: left;
  border: none;
  outline: none;
  border-radius: 0;
  border-style: none;
  box-shadow: none;
  border-color: Transparent;
  font-family: var(--font-family);
  color: var(--main-color-1);
}

.textarea:focus, input:focus {
  outline: none;
  border-radius: 0;
}

.contact-form-container button {
  width: 100%;
  height: 58px;
  padding: 0px;
  border: 0;
  box-shadow: none;
  border-radius: 8px;
  background: none;
  background-color: var(--main-color-2);
  color: var(--main-color-5);
  font-size: var(--form-font-size);
  font-family: var(--font-family);
  text-align: center;
  text-justify: center;
}
