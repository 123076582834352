* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Rethink Sans', sans-serif;
}

:root {
  --main-color-1: #000;
  --main-color-2: #3D473C;
  --main-color-3: #7E8365;
  --main-color-4: #333333;
  --main-color-5: #FFFFFF;
  --secondary-color-1: #CCCCCC;
  --secondary-color-2: #EEE;
  --font-family: 'Rethink Sans', sans-serif;
}
