.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--main-color-1);
  padding-left: 2vh;
  padding-right: 2vh;
  padding-top: 1vh;
  padding-bottom: 1vh;
  top: 0;
  left: auto;
  right: 0;
  position: sticky;
  height: calc(70px + 1vh);
  z-index: 10;
}

.navbar {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar a {
  width: 100%;
  height: 100%;
  padding: 10px;
}

.nav-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-width: 30vw;
  max-height: 65%;
}

.nav-logo path {
  fill: white;
}

.burger-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 12px;
}

.left-menu {
  display: none;
}

.right-menu {
  display: none;
}

@media (min-width: 800px) {
  .header {
    max-width: 100%;
  }

  .navbar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    max-width: 100%;
  }

  .left-menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .right-menu {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .nav-sign-in-button {
    margin-left: 30px;
    border-radius: 8px;
    border: solid 1px #fff;
    padding: 5px 15px;
    text-align: center;
    width: fit-content;
    color: var(--main-color-5);
    font: var(--font-family);
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    text-decoration: none;
    text-wrap: nowrap;
    transition: background-color 0.3s ease, color 0.3s ease;
  }

  .nav-sign-in-button:hover {
    background-color: #fff;
    color: #000;
  }

  .nav-logo-container {
    display: flex;
    height: 6.5vh;
    width: 30vw;
    padding: 0px;
    align-items: center;
    justify-content: center;
  }

  .nav-logo {
    min-width: 0vw;
  }

  .navbar a {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 12px;
    font-family: var(--font-family);
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--main-color-5);
    text-decoration: none;
    text-wrap: nowrap;
  }

  .nav-logo img {
  }

  .burger-button {
    display: none;
  }

  .close-button {
    display: none;
  }
}