.pricing-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.pricing-table thead > tr > th {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3d473c;
}

.pricing-table th,
.pricing-table td {
  padding-left: 4.5vw;
  padding-top: 7vw;
  padding-bottom: 7vw;
  padding-right: 4.5vw;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3d473c;
  font-family: var(--font-family);
}

.pricing-table th {
  background-color: var(--main-color-5);
}

.pricing-table tr:nth-child(odd) {
  background-color: #f9fafe;
}

.pricing-table tr:nth-child(even) {
  background-color: #fff;
}

.pricing-table tr:first-child th:first-child {
  border-top-left-radius: 10px;
}

.pricing-table tr:first-child th:last-child {
  border-top-right-radius: 10px;
}

.pricing-table tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}

.pricing-table tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}
