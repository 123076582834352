.infofooter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: white;
  background-color: black;
  padding: 31px;
  padding-top: 46px;
}

.infofooterlogo {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 35px;
}

.infofooterlogo svg {
  display:flex;
  fill: var(--main-color-5);
  height: 100%;
  width: 100%;
}


.infofooterinner {
  margin-left: 6px;
}

.infofooterinner > .menu > a {
  text-decoration: none;
}

.menu {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 12px;
}

.menu > a {
  flex: 0 0 50%;
  box-sizing: border-box;
  font-weight: 600;
  text-decoration: none;
  color: var(--main-color-5);
  margin-bottom: 22px;
}

.menu-item {
  flex: 0 0 50%;
  box-sizing: border-box;
}

.social-media {
  display: flex;
  flex-direction: row;
  margin-bottom: 34px;
}

.instagram-icon {
  height: 40px;
}

.address,
.contact {
  margin-bottom: 22px;
  color: var(--main-color-5);
}

.contact a {
  color: var(--main-color-5);
}

.contact-us {
  margin-bottom: 22px;
  font-weight: 600;
}

.address a {
  color: var(--main-color-5);

}

.visit-us {
  margin-bottom: 22px;
  font-weight: 600;
}

.actions {
  display: flex;
  gap: 10px;
}

.btn-signin {
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.become-member {
}

.btn-signin {
  background-color: white;
  color: black;
}
