.card-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 7vw;
  border-radius: 10px;
  background-color: var(--main-color-5);
}

.card-container h3 {
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 800;
  margin-bottom: 9px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--main-color-4);
}

.price-box {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 9px;
}

.price-title {
  font-family: var(--font-family);
  font-size: 48px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--main-color-4);
  margin-right: 11px;
}

.term-box {
  display: flex;
  flex-direction: column;
}

.regular-font-style {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--main-color-4);
}

.get-started-button {
  width: 100%;
  height: 60px;
  margin: 18px 0 0;
  border-radius: 10px;
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  border: 0;
  box-shadow: none;
  background-color: var(--main-color-2);
  color: var(--main-color-5);
  text-align: center;
  text-justify: center;
  white-space:nowrap;
}

.division-line {
  width: 100%;
  height: 0.5px;
  background-color: #707070;
  color: #707070;
  margin-bottom: 26px;
  margin-top: 26px;
}

.benefits-title {
  width: 100%;
  height: 24px;
  margin-bottom: 10px;
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #111727;
}

.benefit {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
}

.checkmark {
  margin-right: 10px;
}

.benefit-name {
  width: 100%;
  height: 100%;
  font-family: var(--font-family);
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--main-color-4);
}